//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  background-color: #f7f7f7;
}

.table > thead {
    vertical-align: bottom;
    --ct-table-bg: #f7e7d7!important;
    border-bottom: solid 2px orange;
}

.statut {
    font-size: 16px;
    margin-left: 10px;
    top: -5px;
    position: relative;
}

._id {
    width: 143px;
    overflow: hidden;
}

.objet {
    max-width: 300px;
    display: inline-block;
    overflow-wrap: normal;
    white-space: break-spaces;
}

.creer-ticket {
    font-size: 16px;
    color: #000;
}

tbody {
    font-size: 16px;
    color: #000;
}

.mes-tickets .recherche {
    position: absolute;
    right: 2%;
    margin-top: -50px;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
.custom-col {
  height: 60px;
  padding: 15px;
  border: 1px solid #000;
}

.rdw-editor-wrapper .rdw-editor-main {
  border: solid 1px #f1f1f1;
  padding: 0 20px;
}

.view-ticket-form {
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 50px;
}
#description {
    min-height: 200px;
}
.breadcrumb-item a {
    color: #000;
    font-size: 16px;
}

.breadcrumb-item.active {
    color: #f79425;
    font-weight: 700;
    font-size: 16px;
}
.Comment.row {
    color: #000;
    font-size: 16px;

    .description {
      background: #F8F8F8 0% 0% no-repeat padding-box;
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      padding: 10px;
      width: 100%;
    }

    .date {
      font-size: 14px;
    }
}